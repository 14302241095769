import React from "react";

const HistoryAlarmContainer = Loader.loadBusinessComponent(
  "MonitorHistoryAlarm",
  "HistoryAlarmContainer"
);

const searchDataInit = {
  // threshold: 60.0,
  startTime: undefined,
  endTime: undefined,
  timeType: undefined,
  alarmOperationType: undefined,
  taskIds: undefined,
  monitorPersonKeywords: undefined,
  installationSites: undefined,
  noInstallationSites: undefined,
  libIds: [],
  sort: ["captureTime|desc"],
  captureUids: "",
  offset: 0,
  limit: 24,
  alarmTypes:["1"]
};

const searchList = [
  "AlarmTimeTypeSelect",
  "AlarmStateSelect",
  "AlarmSiteScreening",
  "AlarmTimeRadio"
]

const KeyPersonHisrotyAlarm = () => (
  <HistoryAlarmContainer 
    libType={1}
    moduleName='keyPersonnelHistory'
    detailModuleName='keyPersonnelDetail'
    searchDataInit={searchDataInit}
    searchList={searchList}
    cardType='KeyPointCard'
    cardStyle={{
      itemWidth: 340,
      itemHeight: 350,
      pdWidth: 8,
    }}
    taskType='101501'
    showSearchInput={true}
  />
)

export default KeyPersonHisrotyAlarm;
